/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import styled from '@emotion/styled';
import { Button, Modal, Box, Typography } from '@mui/material';
import Section from 'components/Section';
import SharingsTable from 'components/access/SharingsTable';
import Clipboard from 'react-clipboard.js';
import Chip from '@mui/material/Chip';
import { convertContentToScrape, getAllScrapesByPenlyUserId } from 'actions/scrapes';
import paths from 'paths';
import PdfDialog from 'components/PdfDialog';
import { getUser, getFiles, getSharings, getFileBinary, deleteFile } from 'actions/access';
import { showFile, renderKr, calculateAge, renderDate, convertDateToDanishCPRFirst6Digits } from 'utils/helpers';
import { getTextCollectionText } from 'utils/texts';
import {
    ChildOfEnum,
    OccupationEnum,
    ChildResidenceEnum,
    PartnerRelationEnum,
    SavingsTypeEnum,
    ExtraPensionsTypeEnum,
    DebtTypeEnum,
    HousingTypeEnum,
    InvestmentRiskLevelEnum,
    ProductPackageTextEnum,
    YesNoDontKnowEnum,
    FileTypes,
}  from 'utils/enums';

import { initializePensionCheckResult, getPensionDetails, getSuggestionHeader, getSuggestionSummary } from 'utils/PensionCheck/pensionCheck';
import { config } from 'utils/config';
import CircularProgress from '@mui/material/CircularProgress';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { colors } from 'utils/theme';
import UserInviteForm from 'components/access/UserInviteForm';
import { penly } from 'api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UploadFileModal from 'components/access/UploadFileModal';
import PenlyAccessMessages from './PenlyAccessMessages';

const H6 = styled.h6({
    margin: '0 0 10px',
    fontSize: '18px',
    textTransform: 'uppercase',
    fontWeight: '900',
});

const affiliates = {
    penly: 'Penly',
    taenk: 'Forbrugerrådet Tænk',
    fagligeseniorer: 'Faglige Seniorer',
};

const PenlyAccessSharing = ({ injectedUserId }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { organisationId } = params;
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [files, setFiles] = useState([]);
    const [sharings, setSharings] = useState([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [scrapes, setScrapes] = useState([]);
    const [snack, setSnack] = useState();
    const [pdf, setPdf] = useState();
    const [taenkData, setTaenkData] = useState(null);
    const [pensionCheckResult, setPensionCheckResult] = useState({});
    const [openPartnerInviteModal, setOpenPartnerInviteModal] = useState(false);
    const [copied, setCopied] = React.useState(-1);
    const [loading, setLoading] = useState({});
    const [invitationLink, setInvitationLink] = useState();
    const organisation = useSelector(state => state.auth.user.organisations.find(org => org.organisationId === organisationId));

    const scrapesMap= (scrapes.length && scrapes.reduce((map, obj) => {
        map[obj.fileId] = {token: obj.externalAccessToken, scrapeId: obj.scrapeId}; //eslint-disable-line
        return map;
    }, {})) ?? {};
    const userId = injectedUserId ?? params.userId;
    useEffect(() => {
        const fetch = async () => {
            const user = await dispatch(getUser(organisationId, userId));
            setUser(user);
            const files = (await dispatch(getFiles(organisationId, userId))).sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
            setFiles(files);
            const sharings = (await dispatch(getSharings(organisationId, userId))).sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt));
            setSharings(sharings);
            const scrapes = (await dispatch(getAllScrapesByPenlyUserId(organisationId, userId)));
            setScrapes(scrapes.filter(x => x.status !== 'deleted'));

            setTaenkData(user.data.taenk);
            setPensionCheckResult(initializePensionCheckResult(user.data?.pension?.analysis?.suggestions, false, true, false));
        };
        fetch();
    }, [dispatch, params]);
    const invitePartnerPensionsInfo= async () => {
        const redirectUrl = `https://penly.dk/partner-uniify-flow-complete?userId=${user.userId}`;
        let link;
        try {
            link = await penly.invitePartnerToKreditdata(organisationId, user.userId, { redirectUrl, partnerEmail: household.partner.email });
        } catch (error) {
            if(error.response.status === 429){
                alert('Der er allerede sendt invitation på denne email. Har kunden ikke modtaget en email kan det skyldes at den er blokeret eller de har skrevet forkert email ind');
            } else {
                alert('der skete en fejl');
            }

        }
        setInvitationLink(link);
    };
    if (!user) return null;
    const household = user.data?.household;
    const partner = household?.partner;
    const partnerInviteHistory = user?.integrations?.kreditdata_partner?.history ?? [];
    const partnerHasSharedPensionsInfo = files?.some(x => x.fileType === 'PartnerPensionsInfoFile');
    const hasSharedPensionsInfo = files?.some(x => x.fileType === 'PensionsInfoFile');
    const onClipboardSuccess = (id) => {
        setCopied(id);
        setTimeout(() => setCopied(-1), 2000);
    };

    const handleDownloadFile = async (fileId, fileName) => {
        const content = await dispatch(getFileBinary(organisationId, userId, fileId));
        if (content) showFile(content, fileName);
    };
    const handleExportFile = async (content, fileId) => {
        setLoading({ [fileId]: true });
        await dispatch(convertContentToScrape(content,fileId, userId, organisationId));
        const scrapes = (await dispatch(getAllScrapesByPenlyUserId(organisationId, userId)));
        setScrapes(scrapes);
        setLoading({ [fileId]: false });
    };

    const handleShowFile = async (fileId, fileName) => {
        const content = await dispatch(getFileBinary(organisationId, userId, fileId));
        const reader = new FileReader();
        reader.readAsDataURL(content);
        reader.onloadend = () => {
            const base64data = reader.result;
            if (content) setPdf({ url: base64data, fileName });
        };
    };
    const handleDeleteFile = async (fileId) => {
        await dispatch(deleteFile(organisationId, userId, fileId));
        setFiles(files.filter(x => x.fileId !== fileId));
    };
    const handleClose = (file) => {
        setFiles([file, ...files]);
        setUploadModalOpen(false);
    };
    const getSharingPath = (sharingId) => paths.penlyAccessSharing.getPath(organisationId, sharingId);

    let chosenProduct; let affiliate;
    if (taenkData && taenkData.chosenProducts) {
        chosenProduct = taenkData.chosenProducts[taenkData.chosenProducts.length - 1];
        affiliate = affiliates[taenkData?.chosenProducts[0].affiliateId];
    }

    const message = sharings[sharings.length-1]?.context?.messageToAdvisor;
    const renderPartner = partner => {
        if (!partner) return 'Ingen partner angivet';
        if (partner.relation === PartnerRelationEnum.Spouse) return 'Gift';
        if (partner.relation === PartnerRelationEnum.Cohabitant) return 'Samlever';
        return 'Ukendt';
    };

    const birthdate = user.data?.basic?.birthDate || user.data?.pension?.analysis?.keyFigures?.birthDay;
    const acceptedScopes = sharings?.find(x => x.status === 'accepted')?.acceptedScopes[0];
    const ownershipOptions = [
        { id: 'both', text:  'Begge' },
        { id: 'personal', text: 'Personligt' },
        { id: 'partner', text:  'Partner' },
    ];
    const uniifyUserId = user.data?.budget?.uniifyUserId;
    const uniifyBudgetUrl = `${config.uniifyBaseUrl}/cases/${uniifyUserId}/${uniifyUserId}/${uniifyUserId}/budget`;
    const onCopyCpr = (event) => event.stopPropagation();
    const cpr = convertDateToDanishCPRFirst6Digits(user.data?.basic?.cprNumber || user.data?.pension?.analysis?.keyFigures?.birthDay);
    return (
        <div className="App-content">
            { user.error === 'NotFound' && (
                <Section title="Bruger ikke fundet">
                    <p>Bruger eksisterer ikke. Gå tilbage for at finde hvad du leder efter.</p>
                </Section>
            ) }


            <Section title="Basisinformation">
                <p>Navn: { `${user.firstName ?? ''} ${user.lastName ?? ''}` }</p>
                <p>Email: { user.email }</p>
                { user.data?.basic?.phone  && (<p>Telefonnummer: { user.data.basic.phone }</p>) }
                { cpr &&
                <p>CPR-nr første 6 cifre: { ' ' }
                    <Clipboard onClick={ (e) => onCopyCpr(e) } component='span' data-clipboard-text={ cpr } onSuccess={ () => onClipboardSuccess('cpr') }>
                        <Chip
                            id="clip"
                            onClick={ () =>{} }
                            icon={ copied === 'cpr' ? <CheckIcon style={{ color:colors.positive }}></CheckIcon> :<FileCopyOutlinedIcon style={{ color:colors.black }}></FileCopyOutlinedIcon> }
                            label={ copied === 'cpr' ? `Kopieret ${  cpr}`: cpr }
                        />
                    </Clipboard></p>
                }

            </Section>

            { organisation.enabledFeatures?.includes('PenlyAccessInvite') && (
                <PenlyAccessMessages penlyUser={ user }></PenlyAccessMessages>
            ) }

            <Section title="Filer">


                <TableContainer>
                    <Table caria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Navn</TableCell>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="left">Uploadet tidspunkt</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left" />
                                <TableCell align="left" />
                                <TableCell align="left" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { files.map((file) => (
                                <TableRow key={ file.fileId }>
                                    <TableCell align="left">
                                        { file.fileName }
                                    </TableCell>
                                    <TableCell align="left">
                                        { getTextCollectionText(FileTypes, FileTypes[file.fileType]) || '-' }
                                    </TableCell>
                                    <TableCell align="left">
                                        { new Intl.DateTimeFormat('da-DK', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(file.createdAt)) }
                                    </TableCell>
                                    { (file.fileType === 'PensionsInfoFile' || file.fileType === 'PartnerPensionsInfoFile' ) ?
                                        loading[file.fileId]?
                                            <TableCell align="center"  >
                                                <div className="table-center">
                                                    <CircularProgress  color="primary" size={ 20 } style={{ marginRight:5 }} />
                                                </div>
                                            </TableCell>:
                                            scrapesMap[file.fileId] ?
                                                <TableCell align="center">
                                                    <Clipboard component='div' data-clipboard-text={ scrapesMap[file.fileId].token } onSuccess={ () => onClipboardSuccess(file.fileId) }>
                                                        <Chip
                                                            id="clip"
                                                            onClick={ () =>{} }
                                                            icon={ copied === file.fileId ? (
                                                                <CheckIcon style={{ color:colors.positive }} />
                                                            ) : (
                                                                <FileCopyOutlinedIcon style={{ color:colors.black }} />
                                                            ) }
                                                            label={ copied === file.fileId ? 'Kopieret' : scrapesMap[file.fileId].token }
                                                        />
                                                    </Clipboard>
                                                </TableCell>:
                                                <TableCell align="center" onClick={ () => handleExportFile(file.content, file.fileId, file.fileType === 'PensionsInfoFile' ? user : undefined) }>
                                                    <Button>Generer Token</Button>
                                                </TableCell>: (file.fileType === 'BudgetFile' && !!uniifyUserId) ? <TableCell align="center"><Button href={ uniifyBudgetUrl } target="_blank" rel="noopener noreferrer">Rediger</Button></TableCell>: <TableCell></TableCell>
                                    }
                                    { scrapesMap[file.fileId] ?
                                        <TableCell align="left" onClick={ () => navigate(paths.pensionsInfoScrape.getPath(scrapesMap[file.fileId].scrapeId)) }>
                                            <Button>Detaljer</Button>
                                        </TableCell>:<TableCell></TableCell>
                                    }

                                    <TableCell align="left" >

                                        <Button onClick={ () => handleShowFile(file.fileId, file.fileName) }>Vis</Button>
                                        { file.fileType === 'Advice' &&
                                            <Button color='error' onClick={ () => handleDeleteFile(file.fileId) }>Slet</Button>
                                        }
                                    </TableCell>
                                    <TableCell align="left" onClick={ () => handleDownloadFile(file.fileId, file.fileName) }>
                                        <Button>Download</Button>
                                    </TableCell>
                                </TableRow>
                            )) }
                        </TableBody>
                    </Table>
                </TableContainer>
                { (!files || !files.length) && (
                    <p>
                        Ingen delte filer
                    </p>
                ) }
                <Button style={{ marginTop:20 }}variant="outlined" color="primary" onClick={ () => setUploadModalOpen(!uploadModalOpen) }>Upload fil</Button>
                <UploadFileModal userId={ user.userId } open={ uploadModalOpen } handleClose={ handleClose } maxFileSize={ 5000000 }/>
            </Section>
            {
                user.data &&
                <Section title="Personlige oplysninger">
                    <div style={{ display:'flex' }}>
                        <H6>{ `${user.firstName} ${user.lastName}` }</H6>
                        { hasSharedPensionsInfo && <PictureAsPdfIcon color='primary' style={{ marginLeft: 4, position:'relative', top:-6, fontSize:30 }}/> }
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <th style={{ width: '200px' }}>
                                    Fødselsdag
                                </th>
                                <td>
                                    { `${renderDate(birthdate, false)} (${calculateAge(birthdate)} år)` }
                                </td>
                            </tr>
                            { user.data?.basic?.occupation && (
                                <tr>
                                    <th>
                                        Beskæftigelse
                                    </th>
                                    <td>
                                        { getTextCollectionText(OccupationEnum, user.data.basic.occupation) }
                                    </td>
                                </tr>
                            ) }
                            { user.data?.basic?.jobTitle && (
                                <tr>
                                    <th>
                                        Stillingsbetegnelse
                                    </th>
                                    <td>
                                        { user.data.basic.jobTitle }
                                    </td>
                                </tr>
                            ) }
                            <tr>
                                <th>
                                    Indtægt før skat per måned
                                </th>
                                <td>
                                    { renderKr(user.data.basic?.incomePerMonthBeforeTax) || 'Ukendt' }
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Forbrug per måned
                                </th>
                                <td>
                                    { renderKr(user.data.basic?.consumption) || 'Ukendt' }
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Civilstatus
                                </th>
                                <td>
                                    { renderPartner(partner) }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    { partner &&
                    <>
                        <br /><br />
                        <div style={{ display:'flex' }}>
                            <H6>Partner: { `${partner.name?.firstName ?? partner.firstName ?? 'Navn ikke angivet'} ${partner.name?.lastName ??partner.lastName ?? ''}` }</H6>
                            { partnerHasSharedPensionsInfo && <PictureAsPdfIcon color='primary' style={{ marginLeft: 4, position:'relative', top:-6, fontSize:30 }}/> }
                        </div>

                        <table>
                            <tbody>
                                <tr>
                                    <th style={{ width: '200px' }}>
                                        Alder
                                    </th>
                                    <td>
                                        { partner.age } år
                                    </td>
                                </tr>
                                { partner.occupation && (
                                    <tr>
                                        <th>
                                            Beskæftigelse
                                        </th>
                                        <td>
                                            { getTextCollectionText(OccupationEnum, partner.occupation) }
                                        </td>
                                    </tr>
                                ) }
                                <tr>
                                    <th>
                                        Indtægt før skat per måned
                                    </th>
                                    <td>
                                        { renderKr(partner.incomePerMonthBeforeTax) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Civilstatus
                                    </th>
                                    <td>
                                        { renderPartner(partner) }
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Email
                                    </th>
                                    <td>
                                        { partner.email }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            style={{ display: 'block' }}
                            onClick={ () => setOpenPartnerInviteModal(true) }
                        >
                            Inviter Partner til at oprette profil
                        </Button>
                        <Modal open={ openPartnerInviteModal } onClose={ () => setOpenPartnerInviteModal(false) }>
                            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4,  overflowY: 'scroll', maxHeight:'95vh' }}>
                                <Typography variant="h4"  sx={{ mb: 2 }}>
                                    Inviter Partner
                                </Typography>
                                <UserInviteForm onClose={ () => setOpenPartnerInviteModal(false) } initiator={{ name: `${user.firstName} ${user.lastName}`, type: 'partner', email: user.email, userId: user.userId }} defaultState={{ name: `${partner.name?.firstName ?? partner.firstName ?? ''} ${partner.name?.lastName ??partner.lastName ?? ''}`, email: partner.email, scope: acceptedScopes }}/>
                                <div style={{ display:'flex', justifyContent: 'flex-end' }}>
                                    <Button  onClick={ () => setOpenPartnerInviteModal(false) } sx={{ mt: 2 }}>
                                        Luk
                                    </Button>
                                </div>

                            </Box>
                        </Modal>
                        { partnerInviteHistory.length > 0 && <p><strong>Invitationer fra bruger:</strong> { partnerInviteHistory.map(x => x.email).join(',') }</p> }
                        { household.partner.pensionsInfo?.invitationLink &&
                        <>
                            <Typography style={{ marginTop:20 }}variant='h6'>Pensionsinfo - inviteret med link:</Typography>
                            { household.partner.pensionsInfo.email && household.partner.email !== household.partner.pensionsInfo.email &&   (<p style={{ color:'red' }}><strong>E-mailadresse:</strong> { household.partner.pensionsInfo.email }</p>) }
                            { household.partner.pensionsInfo.date && (<p><strong>Dato:</strong> { renderDate(household.partner.pensionsInfo.date) }</p>) }
                            <Clipboard style={{ display: 'flex' }} component='div' data-clipboard-text={ household.partner.pensionsInfo.invitationLink  }>
                                <TextField
                                    variant="outlined"
                                    value={ household.partner.pensionsInfo.invitationLink  }
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            background: 'white',
                                        },
                                        minWidth: 400,
                                    }}
                                    disabled
                                />
                                <Button
                                    variant="contained"
                                    endIcon={ <ContentCopyIcon /> }
                                    sx={{ ml: 1 }}
                                >
                                    Copy
                                </Button>
                            </Clipboard>
                        </>

                        }
                        {
                        // eslint-disable-next-line
                        !household.partner.pensionsInfo?.invitationLink || household.partner.email !== household.partner.pensionsInfo.email &&
                        <>
                            { !invitationLink &&
                            <Button
                                style={{ marginTop:20 }}
                                variant="contained"
                                onClick={ invitePartnerPensionsInfo }
                            >
                                Inviter partner til at dele pensionsinfo
                            </Button>
                            }

                            { invitationLink &&
                            <>
                                <Typography variant='h6'>Pensionsinfo - inviteret med link:</Typography>
                                <Clipboard style={{ display: 'flex' }} component='div' data-clipboard-text={ invitationLink  }>
                                    <TextField
                                        variant="outlined"
                                        value={ invitationLink  }
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                background: 'white',
                                            },
                                            minWidth: 400,
                                        }}
                                        disabled
                                    />
                                    <Button
                                        variant="contained"
                                        endIcon={ <ContentCopyIcon /> }
                                        sx={{ ml: 1 }}
                                    >
                                        Copy
                                    </Button>
                                </Clipboard>
                            </>
                            }
                        </>
                        }
                    </>
                    }

                    <br /><br />

                    <H6>Børn</H6>
                    {
                        (user.data.basic.children && user.data.basic.children.length > 0) ?
                            <>
                                <table>
                                    <tbody>
                                        { user.data.basic.children.map(child => (
                                            <React.Fragment key={ child.id }>
                                                <tr>
                                                    <th style={{ width: '200px' }}>
                                                        { child.name }
                                                    </th>
                                                    <td>
                                                        { child.age } år, { getTextCollectionText(ChildResidenceEnum, child.residence) }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Forældremyndighed
                                                    </th>
                                                    <td>
                                                        { getTextCollectionText(ChildOfEnum, child.childOf) }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={ 2 } style={{ height: '10px' }}></td>
                                                </tr>
                                            </React.Fragment>
                                        )) }
                                    </tbody>
                                </table>
                                <br />
                            </>
                            :
                            <>
                                <span>Ingen børn</span>
                                <br /><br />
                            </>
                    }

                    <H6>Opsparinger</H6>
                    {
                        (user.data.savings?.savings?.length) ?
                            <>
                                <table>
                                    <tbody>
                                        { user.data.savings.savings.map(saving => (
                                            <React.Fragment key={ saving.id }>
                                                <tr>
                                                    <th style={{ width: '200px' }}>
                                                        { saving.name }
                                                    </th>
                                                    <td>
                                                        { renderKr(saving.value ?? saving.totalSavings) }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Opsparingstype
                                                    </th>
                                                    <td>
                                                        { getTextCollectionText(SavingsTypeEnum, saving.type) }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Ejer
                                                    </th>
                                                    <td>
                                                        { ownershipOptions.find(x => x.id === saving.ownership)?.text ?? 'Ikke angivet' }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={ 2 } style={{ height: '10px' }}></td>
                                                </tr>
                                            </React.Fragment>
                                        )) }
                                    </tbody>
                                </table>
                                <br />
                            </>
                            :
                            <>
                                <span>Ingen opsparing angivet</span>
                                <br /><br />
                            </>
                    }
                    <H6>Formuer og værdier</H6>
                    <table>
                        <tbody>
                            { user.data?.valuables?.valuables &&  user.data.valuables.valuables?.map(valuable => (
                                <React.Fragment key={ valuable.id }>
                                    <tr>
                                        <th style={{ width: '200px' }}>
                                            { valuable.name }
                                        </th>
                                        <td>
                                            { renderKr(valuable.value) }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Ejer
                                        </th>
                                        <td>
                                            { ownershipOptions.find(x => x.id === valuable.ownership)?.text ?? 'Ikke angivet' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={ 2 } style={{ height: '10px' }}></td>
                                    </tr>
                                </React.Fragment>
                            )) }
                        </tbody>
                    </table>
                    <br /><br />
                    <H6>Yderligere Pensioner  </H6>
                    <table>
                        <tbody>
                            { user.data?.pension?.extraPensions?.extraPensions &&  user.data.pension.extraPensions.extraPensions?.map(pension => (
                                <React.Fragment key={ pension.id }>
                                    <tr>
                                        <th style={{ width: '200px' }}>
                                            { pension.name }
                                        </th>
                                        <td>
                                            { renderKr(pension.value) }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Opsparingsform
                                        </th>
                                        <td>
                                            { getTextCollectionText(ExtraPensionsTypeEnum, pension.type) }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Ejer
                                        </th>
                                        <td>
                                            { ownershipOptions.find(x => x.id === pension.ownership)?.text ?? 'Ikke angivet' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={ 2 } style={{ height: '10px' }}></td>
                                    </tr>
                                </React.Fragment>
                            )) }
                        </tbody>
                    </table>
                    <br /><br />
                    <H6>Gæld</H6>
                    {
                    // eslint-disable-next-line no-nested-ternary
                        (user.data.debts?.debts?.length) ?
                            <>
                                <table>
                                    <tbody>
                                        { user.data.debts.debts.map(debt => (
                                            <React.Fragment key={ debt.id }>
                                                <tr>
                                                    <th style={{ width: '200px' }}>
                                                        { getTextCollectionText(DebtTypeEnum, debt.type) }
                                                    </th>
                                                    <td>
                                                        { renderKr(debt.value) }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Ejer
                                                    </th>
                                                    <td>
                                                        { ownershipOptions.find(x => x.id === debt.ownership)?.text ?? 'Ikke angivet' }
                                                    </td>
                                                </tr>
                                                { debt.principal && (
                                                    <tr>
                                                        <th>Hovedstol</th>
                                                        <td>{ renderKr(debt.principal) }</td>
                                                    </tr>
                                                ) }
                                                { debt.interestRate && (
                                                    <tr>
                                                        <th>Rente</th>
                                                        <td>{ `${debt.interestRate  } %` }</td>
                                                    </tr>
                                                ) }
                                                { debt.contribution && (
                                                    <tr>
                                                        <th>Bidrag</th>
                                                        <td>{ `${debt.contribution  } %` }</td>
                                                    </tr>
                                                ) }
                                                { debt.term && (
                                                    <tr>
                                                        <th>Restløbetid</th>
                                                        <td>{ `${debt.term  } år` }</td>
                                                    </tr>
                                                ) }
                                                { debt.paymentsPerYear && (
                                                    <tr>
                                                        <th>Antal ydelser om året</th>
                                                        <td>{ debt.paymentsPerYear }</td>
                                                    </tr>
                                                ) }
                                                { debt.payment && (
                                                    <tr>
                                                        <th>Ydelse</th>
                                                        <td>{ renderKr(debt.payment) }</td>
                                                    </tr>
                                                ) }

                                                { debt.code && (
                                                    <tr>
                                                        <th>Fondskode</th>
                                                        <td>{ debt.code }</td>
                                                    </tr>
                                                ) }



                                                <tr>
                                                    <td colSpan={ 2 } style={{ height: '10px' }}></td>
                                                </tr>
                                            </React.Fragment>
                                        )) }
                                    </tbody>
                                </table>
                                <br />
                            </>
                            :
                            (user.data.debts?.debts?.length) ?
                                <>
                                    <table>
                                        <tbody>
                                            { user.data.debts.debts.map(debt => (
                                                <React.Fragment key={ debt.id }>
                                                    <tr>
                                                        <th style={{ width: '200px' }}>
                                                            { getTextCollectionText(DebtTypeEnum, debt.type) }
                                                        </th>
                                                        <td>
                                                            { renderKr(debt.remainingDebt) }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Ejer
                                                        </th>
                                                        <td>
                                                            { ownershipOptions.find(x => x.id === debt.ownership)?.text ?? 'Ikke angivet' }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={ 2 } style={{ height: '10px' }}></td>
                                                    </tr>
                                                </React.Fragment>
                                            )) }
                                        </tbody>
                                    </table>
                                    <br />
                                </>
                                :
                                <>
                                    <span>Ingen gæld angivet</span>
                                    <br /><br />
                                </>
                    }

                    <H6>Bolig</H6>
                    {
                        (user.data.properties?.properties?.length) ?
                            <>
                                <table>
                                    <tbody>
                                        { user.data.properties.properties.map(property => (
                                            <React.Fragment key={ property.id }>
                                                <tr>
                                                    <th style={{ width: '200px' }}>
                                                        { getTextCollectionText(HousingTypeEnum, property.housingType ?? property.type ) }
                                                    </th>
                                                </tr>
                                                { property.value && (
                                                    <tr>
                                                        <th>
                                                            Estimeret værdi
                                                        </th>
                                                        <td>
                                                            { renderKr(property.value) }
                                                        </td>
                                                    </tr>
                                                ) }
                                                { property.ownership && (
                                                    <tr>
                                                        <th>
                                                            Ejer
                                                        </th>
                                                        <td>
                                                            { ownershipOptions.find(x => x.id === property.ownership)?.text ?? 'Ikke angivet' }
                                                        </td>
                                                    </tr>
                                                ) }
                                                { property.address && (
                                                    <>
                                                        { property.address?.zipCode ? (
                                                            <tr>
                                                                { property.address.address }<br/>
                                                                { property.address.zipCode } { property.address.city }
                                                            </tr>
                                                        ) : (

                                                            <tr>
                                                                { property.address }<br/>
                                                                { property.zipCode } { property.city }
                                                            </tr>
                                                        ) }
                                                    </>
                                                ) }

                                                <tr>
                                                    <td colSpan={ 2 } style={{ height: '10px' }}></td>
                                                </tr>
                                            </React.Fragment>
                                        )) }
                                    </tbody>
                                </table>
                                <br />
                            </>
                            :
                            <>
                                <span>Ingen boliger angivet</span>
                                <br /><br />
                            </>
                    }
                    <H6>Forsikringer</H6>
                    <table>
                        <tbody>
                            { user.data.insurance?.haveSalaryInsurance && (
                                <tr>
                                    <th style={{ width: '200px' }}>
                                        Har lønsikring
                                    </th>
                                    { user.data.insurance?.haveSalaryInsurance === 'no' &&
                                    <td>
                                        Nej
                                    </td>
                                    }
                                    { user.data.insurance?.haveSalaryInsurance === 'maybe' &&
                                    <td>
                                        Måske
                                    </td>
                                    }
                                    { user.data.insurance?.haveSalaryInsurance === 'yes' &&
                                    <td>
                                        { `Ja, med dækningsgrad på ${user.data.insurance.salaryInsuranceCoverage}% i ${user.data.insurance.salaryInsuranceDuration} måneder` }
                                    </td>
                                    }
                                </tr>
                            ) }
                            { user.data.basic?.haveTestament && (
                                <tr>
                                    <th>
                                        Har testamente
                                    </th>
                                    <td>
                                        { user.data.basic.haveTestament ? 'Ja' : 'Nej' }
                                    </td>
                                </tr>
                            ) }
                        </tbody>
                    </table>
                    <br /><br />

                    <H6>Spørgeskemaer</H6>
                    <table>
                        <tbody>
                            <tr>
                                { user.data.surveys?.confidenceInPersonalEconomy?.value  &&
                                <>
                                    <th style={{ width: '200px' }}>
                                        Økonomisk tryghed
                                    </th>
                                    <td>
                                        { user.data.surveys.confidenceInPersonalEconomy.value }
                                    </td>
                                </>
                                }

                            </tr>
                            {
                                user.data.surveys?.mortgageAdvisoryPurpose  &&
                                <>
                                    <tr>
                                        <th style={{ width: '200px' }}>
                                            Hvad ønsker du at opnå med lånerådgivningen?
                                        </th>
                                        { user.data.surveys?.mortgageAdvisoryPurpose ?
                                            <td>
                                                { user.data.surveys.mortgageAdvisoryPurpose.value }

                                            </td>:
                                            <td>
                                                Ikke besvaret
                                            </td>
                                        }
                                    </tr>
                                    { user.data.surveys?.mortgageAdvisoryPurpose?.message &&
                                    <tr>
                                        <th style={{ width: '200px' }}>
                                            Beskrivelse
                                        </th>
                                        <td>
                                            { user.data.surveys.mortgageAdvisoryPurpose.message }
                                        </td>
                                    </tr>

                                    }

                                </>
                            }

                        </tbody>
                    </table>
                    <br /><br />

                    <H6>Andet</H6>
                    <table>
                        <tbody>
                            { user.data.pension?.base?.investmentRiskLevel  && (
                                <tr>
                                    <th style={{ width: '200px' }}>
                                        Investeringsrisiko
                                    </th>
                                    <td>
                                        { getTextCollectionText(InvestmentRiskLevelEnum, user.data.pension.base.investmentRiskLevel ) }
                                    </td>
                                </tr>
                            ) }
                            { user.data.pension?.base?.pensionAge  && (
                                <tr>
                                    <th style={{ width: '200px' }}>
                                        Ønsket pensionsalder
                                    </th>
                                    <td>
                                        { user.data.pension?.base?.pensionAge }
                                    </td>
                                </tr>
                            ) }
                            { user.data.pension?.base?.depositsToEarlyRetirementPay  && (
                                <tr>
                                    <th style={{ width: '200px' }}>
                                        Indbetaler til efterløn
                                    </th>
                                    <td>
                                        { getTextCollectionText(YesNoDontKnowEnum, user.data.pension.base.depositsToEarlyRetirementPay ) }
                                    </td>
                                </tr>
                            ) }
                            { user.data.basic?.haveTestament && (
                                <tr>
                                    <th>
                                        Har testamente
                                    </th>
                                    <td>
                                        { user.data.basic.haveTestament ? 'Ja' : 'Nej' }
                                    </td>
                                </tr>
                            ) }
                            { affiliate && chosenProduct && (
                                <>
                                    <tr>
                                        <th>
                                            Affliate
                                        </th>
                                        <td>
                                            { affiliate }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Produkt
                                        </th>
                                        <td>
                                            { ProductPackageTextEnum[chosenProduct.productId] }
                                        </td>
                                    </tr>
                                </>
                            ) }
                            { message && (
                                <tr>
                                    <th style={{ verticalAlign: 'top' }}>
                                        Besked({ renderDate(sharings[sharings.length-1]?.createdAt, false) })
                                    </th>
                                    <td style={{ whiteSpace: 'pre-line' }}>
                                        { message }
                                    </td>
                                </tr>
                            ) }
                        </tbody>
                    </table>

                </Section>

            }


            <Section title="PensionTjek resultat">
                { pensionCheckResult.hasSuggestions ?
                    <>
                        <div>
                            { pensionCheckResult?.suggestions.map((suggestion, index) => (
                                <div key={ suggestion.id }>
                                    <div id={ `suggestion-${suggestion.type}` } data-index={ index + 1 }>
                                        <H6>{ getSuggestionHeader(suggestion) }</H6>
                                    </div>

                                    <div>
                                        <p><strong>Forhold:</strong> { getSuggestionSummary(suggestion) }</p>
                                        <div className="providers">
                                            { suggestion?.providers?.map((provider, index) => (
                                                <div key={ index }>
                                                    <strong>{ provider.leverandoer }</strong>
                                                    <p>{ getPensionDetails(provider) }</p>
                                                </div>
                                            )) }
                                            <br />
                                        </div>
                                    </div>

                                    <div>
                                        <h4>{ suggestion.actionTitle }</h4>
                                    </div>

                                    <div>
                                        <p>
                                            { suggestion.actionText }
                                        </p>
                                    </div>
                                    <br /><br />
                                </div>
                            )) }

                        </div>
                    </>
                    :
                    <>
                        <div>Ingen PensionsTjek resultater fundet.</div>
                    </>
                }
            </Section>

            <Section title="Delinger">
                <SharingsTable
                    sharings={ sharings }
                    getSharingPath={ getSharingPath }
                />
            </Section>

            { config.env === 'dev' && (
                <Section title="Raw debug">
                    <textarea rows={ 8 } cols={ 80 } defaultValue={ JSON.stringify(user, null, 2) } />
                </Section>
            ) }

            <Snackbar
                open={ !!snack }
                autoHideDuration={ 6000 }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={ () => setSnack() }
            >
                <MuiAlert severity={ snack?.severity || 'success' }>{ snack?.message }</MuiAlert>
            </Snackbar>

            <PdfDialog
                pdf={ pdf }
                onClose={ setPdf }
            />
        </div>
    );
};

export default PenlyAccessSharing;
